import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Criticisms of the Methodology`}</h1>
    <ul>
      <li parentName="ul"><strong parentName="li">{`This page will be expanded`}</strong></li>
      <li parentName="ul">{`Observers here may mean academics, participants, those within organizations who have colleagues using the tool, etc.`}</li>
      <li parentName="ul">{`Observers have reasonably asked whether `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` is a tool for surveying a population, or for developing consensus. Whether the exercise has a goal or not, and opinions about whether it should, in either case, open up the methodology to critique.`}
        <ul parentName="li">
          <li parentName="ul">{`This is especially true given that `}<a parentName="li" {...{
              "href": "/participants",
              "title": "participants"
            }}>{`participants`}</a>{` may see the full or partial results of the `}<a parentName="li" {...{
              "href": "/conversation",
              "title": "conversation"
            }}>{`conversation`}</a>{` while they are participating in it. There is a broader discussion to be had in the academic community about the social aspects of opinion formation, and whether that's normal and good. See, for instance: `}<a parentName="li" {...{
              "href": "https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0078433"
            }}>{`https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0078433`}</a>{` `}<a parentName="li" {...{
              "href": "/Polis",
              "title": "Polis"
            }}>{`Polis`}</a>{` and CompDem look forward to engaging in future discussions on this topic, as it will inevitably instruct future development.`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      